<template>
  <v-row class="row--35 align-center">
    <v-col md="5" sm="12" cols="12">
      <div class="thumbnail">
        <img
          class="w-100"
          src="../../assets/images/about/about-5.jpg"
          alt="About Images"
        />
      </div>
    </v-col>
    <v-col md="7" sm="12" cols="12">
      <div class="about-inner inner">
        <div class="section-title">
          <span class="subtitle">Read About Us</span>
          <h2 class="heading-title">My About</h2>
          <p class="description">
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which dont look even slightly
            believable. If you are going to use a passage of Lorem Ipsum.
          </p>
        </div>
        <v-row>
          <v-col md="6" sm="6" cols="12">
            <div class="about-us-list">
              <h5 class="heading-title">Educational Skill</h5>
              <p>Lorem ipsum dolor sit amet, onecis et mollis.</p>
            </div>
          </v-col>
          <v-col md="6" sm="6" cols="12">
            <div class="about-us-list">
              <h5 class="heading-title">My Strategy</h5>
              <p>Lorem ipsum dolor sit amet, onecis et mollis.</p>
            </div>
          </v-col>
          <v-col md="6" sm="6" cols="12">
            <div class="about-us-list">
              <h5 class="heading-title">Development Skill</h5>
              <p>Lorem ipsum dolor sit amet, onecis et mollis.</p>
            </div>
          </v-col>
          <v-col md="6" sm="6" cols="12">
            <div class="about-us-list">
              <h5 class="heading-title">My Quality</h5>
              <p>Lorem ipsum dolor sit amet, onecis et mollis.</p>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
  export default {};
</script>

<style lang="scss" scoped></style>
